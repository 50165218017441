import React, { useEffect, useContext } from "react";
import { db } from "../firebase/firebase";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useAuth } from "../contexts/authContext/Index";
import { ChordsContext } from "../components/ChordsContext";
import indexImg from "../assets/home/index.png";
import searchImg from "../assets/home/search.png";
import favoritesImg from "../assets/home/favorites.png";
import tunerImg from "../assets/home/tuner.png";
import { Link } from "react-router-dom";
import "./Home.css";

export const Home = () => {
  const { userLoggedIn } = useAuth();
  const { setAllHymns } = useContext(ChordsContext);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    const fetchDataAndUpdateCache = async () => {
      try {
        let q = null;
        if (userLoggedIn) {
          q = query(collection(db, "hymns"));
        } else {
          q = query(collection(db, "hymns"), where("visible", "==", true));
        }

        // Get the data from Firestore
        const querySnapshot = await getDocs(q, { source: "server" });
        let items = querySnapshot.docs
          .map((doc) => ({
            title: doc.title,
            ...doc.data(),
          }))
          .sort((a, b) =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase())
          );
        if (items.length === 0) {
          // Fallback to server if no data is available in the cache
          const serverQuerySnapshot = await getDocs(q, { source: "server" });
          items = serverQuerySnapshot.docs
            .map((doc) => ({
              title: doc.title,
              ...doc.data(),
            }))
            .sort((a, b) =>
              a.title.toLowerCase().localeCompare(b.title.toLowerCase())
            );
        }
        setAllHymns(items);
        localStorage.setItem("cachedHymns", JSON.stringify(items));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndUpdateCache();

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(
      query(collection(db, "hymns")),
      (snapshot) => {
        const newData = snapshot.docs
          .map((doc) => ({
            title: doc.title,
            ...doc.data(),
          }))
          .sort((a, b) =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase())
          );
        setAllHymns(newData);
      }
    );

    // Clean up the subscription on component unmount
    return () => unsubscribe();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {userLoggedIn ? (
        <>
          <div className="image-row pt-20">
            <div className="image-item pr-3">
              <Link to="/Index">
                <img src={indexImg} alt="Index" />
              </Link>
            </div>
            <div className="image-item pl-3">
              <Link to="/Search">
                <img src={searchImg} alt="Search" />{" "}
              </Link>
            </div>
          </div>

          <div className="image-row pt-4">
            <div className="image-item pr-3">
              <Link to="/Favorites">
                <img src={favoritesImg} alt="Favorites" />
              </Link>
            </div>
            <div className="image-item pl-3">
              <Link to="/Tuner">
                <img src={tunerImg} alt="Tuner" />
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div className="not-logged-in-message text-center pt-20 px-4">
          <h4>Η πρόσβαση στο hymnal γίνεται μόνο μέσω της εφαρμογής.</h4>
          <p>Κατεβάστε την εφαρμογή eHymnal από τα παρακάτω links:</p>
          <div className="store-links mt-4 flex justify-center">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Κατεβάστε το από το Google Play"
              style={{ height: "50px", margin: "10px", cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.torakis.hymnal",
                  "_blank"
                )
              }
            />
            <img
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="Κατεβάστε το από το App Store"
              style={{ height: "50px", margin: "10px", cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/ehymnal/id6720749703",
                  "_blank"
                )
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
