export const transliterateGreekToEnglish = (text) => {
  const greekToEnglishMap = {
    Α: "a",
    Β: "b",
    Γ: "g",
    Δ: "d",
    Ε: "e",
    Ζ: "z",
    Η: "i",
    Θ: "th",
    Ι: "i",
    Κ: "k",
    Λ: "l",
    Μ: "m",
    Ν: "n",
    Ξ: "x",
    Ο: "o",
    Π: "p",
    Ρ: "r",
    Σ: "s",
    Τ: "t",
    Υ: "y",
    Φ: "f",
    Χ: "ch",
    Ψ: "ps",
    Ω: "o",
    α: "a",
    β: "b",
    γ: "g",
    δ: "d",
    ε: "e",
    ζ: "z",
    η: "i",
    θ: "th",
    ι: "i",
    κ: "k",
    λ: "l",
    μ: "m",
    ν: "n",
    ξ: "x",
    ο: "o",
    π: "p",
    ρ: "r",
    σ: "s",
    ς: "s",
    τ: "t",
    υ: "y",
    φ: "f",
    χ: "ch",
    ψ: "ps",
    ω: "o",

    // Greek letters with tonos (accent marks)
    ά: "a",
    Ά: "a",
    έ: "e",
    Έ: "e",
    ή: "i",
    Ή: "i",
    ί: "i",
    Ί: "i",
    ό: "o",
    Ό: "o",
    ύ: "y",
    Ύ: "y",
    ώ: "o",
    Ώ: "o",
    ϊ: "i",
    Ϊ: "i",
    ΐ: "i",
    Ϋ: "y",
    ΰ: "y",
  };

  return text
    .split("")
    .map((char) => greekToEnglishMap[char] || char)
    .join("")
    .replace(/ /g, "_") // Replace spaces with underscores
    .replace(/[^a-zA-Z0-9_]/g, ""); // Remove special characters
};
