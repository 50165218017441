import {
  React,
  useEffect,
  useContext,
  useState,
  useRef,
  createRef,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { List, ListItem } from "@material-ui/core";
import { useNavigate, createSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import AddToFavoritesIcon from "../assets/hymn/add_to_favorites.png";
import AddToFavoritesSelectedIcon from "../assets/hymn/add_to_favorites_selected.png";
import { ChordsContext } from "../components/ChordsContext";
import AddToFavorites from "../components/AddToFavorites";
import {
  updateDoc,
  getDocs,
  query,
  collection,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import Button from "@mui/material/Button";
import { hymnCategories } from "../assets/hymnCategories";
import { useAuth } from "../contexts/authContext/Index";
import "./HymnsIndex.css";

export const HymnsIndex = () => {
  const navigate = useNavigate();
  const { setSearch, setResults, allHymns, setAllHymns } =
    useContext(ChordsContext);
  const [showAddToFavoritesModal, setShowAddToFavoritesModal] = useState(false);
  const [addHymnToFavorites, setAddHymnToFavorites] = useState(null);
  const [selectedHymnIcons, setSelectedHymnIcons] = useState({});
  const scrollPositionRef = useRef(0);
  const boxRef = useRef(null);
  const letterRefs = useRef({});
  const [selectingMode, setSelectingMode] = useState(false);
  const [selectedHymns, setSelectedHymns] = useState([]);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedBulkCategory, setSelectedBulkCategory] = useState("");
  const { userLoggedIn } = useAuth();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    const fetchHymnsIfEmpty = async () => {
      if (!allHymns || allHymns.length === 0) {
        try {
          let q = null;
          if (userLoggedIn) {
            q = query(collection(db, "hymns"));
          } else {
            q = query(collection(db, "hymns"), where("visible", "==", true));
          }

          const querySnapshot = await getDocs(q);
          const hymns = querySnapshot.docs
            .map((doc) => ({
              title: doc.title,
              ...doc.data(),
            }))
            .sort((a, b) =>
              a.title.toLowerCase().localeCompare(b.title.toLowerCase())
            );

          setAllHymns(hymns);
          localStorage.setItem("cachedHymns", JSON.stringify(hymns));
        } catch (err) {
          console.error("Error loading hymns:", err);
        }
      }
    };

    fetchHymnsIfEmpty();
  }, [allHymns, setAllHymns, userLoggedIn]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("scrollPosition", scrollPositionRef.current);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const storedScrollPosition = localStorage.getItem("scrollPosition");
    if (storedScrollPosition) {
      boxRef.current.scrollTop = parseInt(storedScrollPosition, 10);
    }

    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const hymnIcons = {};

    allHymns.forEach((hymn) => {
      const initialSelectedCategories = storedFavorites.reduce(
        (acc, item, index) => {
          if (item.content && item.content.includes(hymn.title)) {
            acc.push(index);
          }
          return acc;
        },
        []
      );
      hymnIcons[hymn.title] = initialSelectedCategories.length > 0;
    });

    setSelectedHymnIcons(hymnIcons);
  }, [allHymns, showAddToFavoritesModal]);

  const handleBulkAddToCategory = async (category) => {
    if (!category) return;

    const hymnsRef = collection(db, "hymns");

    for (const title of selectedHymns) {
      const snapshot = await getDocs(
        query(hymnsRef, where("title", "==", title))
      );
      if (!snapshot.empty) {
        const docRef = snapshot.docs[0].ref;
        await updateDoc(docRef, { category });
      }
    }

    setSelectedHymns([]);
    setSelectingMode(false);
    setSelectedBulkCategory("");
    alert("Οι ύμνοι προστέθηκαν στην κατηγορία!");
  };

  const handleClick = (value) => {
    localStorage.setItem("scrollPosition", scrollPositionRef.current);
    setSearch("");
    setResults([]);
    navigate({
      pathname: "/Hymn",
      search: createSearchParams({
        title: value,
      }).toString(),
    });
  };

  const handleAddToFavorites = (item) => {
    setShowAddToFavoritesModal(!showAddToFavoritesModal);
    setAddHymnToFavorites(item.title);
  };

  const handleScroll = () => {
    scrollPositionRef.current = boxRef.current.scrollTop;
  };

  const scrollToLetter = (letter) => {
    const ref = letterRefs.current[letter];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Utility function to remove accents
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  // Extract unique initial letters without accents
  const initialLetters = Array.from(
    new Set(allHymns.map((hymn) => removeAccents(hymn.title[0].toUpperCase())))
  ).sort();

  // Initialize refs for each letter only once
  if (!Object.keys(letterRefs.current).length) {
    initialLetters.forEach((letter) => {
      letterRefs.current[letter] = createRef();
    });
  }

  // Track the first occurrence of each letter
  const seenLetters = new Set();

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          height: "70vh",
          overflowY: "auto",
          padding: "10px",
          borderRight: "1px solid #E4E4E4",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        {initialLetters.map((letter) => (
          <div
            key={letter}
            onClick={() => scrollToLetter(letter)}
            style={{ cursor: "pointer", margin: "5px 0" }}
          >
            {letter}
          </div>
        ))}
      </div>
      <Box
        ref={boxRef}
        onScroll={handleScroll}
        sx={{
          width: "100%",
          height: "74vh",
          overflowY: "auto",
          bgcolor: "background.paper",
        }}
      >
        <nav>
          <List>
            {allHymns.map((item, index) => {
              const firstLetter = removeAccents(item.title[0].toUpperCase());
              if (!seenLetters.has(firstLetter)) {
                seenLetters.add(firstLetter);
                return (
                  <ListItem
                    key={index}
                    divider
                    onClick={() => handleClick(item.title)}
                    ref={letterRefs.current[firstLetter]}
                    style={{ color: item.visible ? "inherit" : "red" }}
                    sx={{ paddingLeft: 0 }}
                  >
                    <ListItemButton>
                      {selectingMode && (
                        <input
                          type="checkbox"
                          checked={selectedHymns.includes(item.title)}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            e.stopPropagation();
                            const isChecked = e.target.checked;
                            setSelectedHymns((prev) =>
                              isChecked
                                ? [...prev, item.title]
                                : prev.filter((t) => t !== item.title)
                            );
                          }}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      <ListItemText
                        primary={`${item.title} ${
                          item.number ? `(${item.number})` : ""
                        }`}
                      />
                    </ListItemButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToFavorites(item);
                      }}
                    >
                      <img
                        src={
                          selectedHymnIcons[item.title]
                            ? AddToFavoritesSelectedIcon
                            : AddToFavoritesIcon
                        }
                        alt="Add to Favorites"
                        style={{ width: 30, height: 30 }}
                      />
                    </IconButton>
                  </ListItem>
                );
              } else {
                return (
                  <ListItem
                    key={index}
                    divider
                    onClick={() => handleClick(item.title)}
                    style={{ color: item.visible ? "inherit" : "red" }}
                  >
                    <ListItemButton>
                      {selectingMode && (
                        <input
                          type="checkbox"
                          checked={selectedHymns.includes(item.title)}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            e.stopPropagation();
                            const isChecked = e.target.checked;
                            setSelectedHymns((prev) =>
                              isChecked
                                ? [...prev, item.title]
                                : prev.filter((t) => t !== item.title)
                            );
                          }}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      <ListItemText
                        primary={`${item.title} ${
                          item.number ? `(${item.number})` : ""
                        }`}
                      />
                    </ListItemButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToFavorites(item);
                      }}
                    >
                      <img
                        src={
                          selectedHymnIcons[item.title]
                            ? AddToFavoritesSelectedIcon
                            : AddToFavoritesIcon
                        }
                        alt="Add to Favorites"
                        style={{ width: 30, height: 30 }}
                      />
                    </IconButton>
                  </ListItem>
                );
              }
            })}
          </List>
        </nav>
        <footer
          style={{
            padding: "1rem",
            textAlign: "right",
            fontFamily: "'Manrope', sans-serif",
          }}
        >
          Σύνολο: {allHymns.length} από {allHymns.length}
        </footer>
      </Box>
      {userLoggedIn &&
        (selectingMode ? (
          <div
            style={{
              position: "fixed",
              bottom: 84,
              left: 16,
              right: 16,
              zIndex: 1000,
              borderRadius: 12,
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
              padding: "12px 16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setSelectingMode(false);
                setSelectedHymns([]);
              }}
            >
              Ακύρωση
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedHymns.length === 0}
              onClick={() => setShowCategoryModal(true)}
            >
              Προσθήκη σε κατηγορία
            </Button>
          </div>
        ) : (
          <div
            style={{
              position: "fixed",
              bottom: 84,
              right: 24,
              zIndex: 1100,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                minWidth: "56px",
                minHeight: "56px",
                fontSize: "12px",
              }}
              onClick={() => setSelectingMode(true)}
            >
              Επιλογή Πολλαπλών
            </Button>
          </div>
        ))}
      ;
      <AddToFavorites
        showAddToFavoritesModal={showAddToFavoritesModal}
        setShowAddToFavoritesModal={setShowAddToFavoritesModal}
        addHymnToFavorites={addHymnToFavorites}
      ></AddToFavorites>
      {showCategoryModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "24px",
              borderRadius: "12px",
              width: "300px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
            }}
          >
            <h5 style={{ marginBottom: "1rem" }}>Επιλέξτε Κατηγορία</h5>
            <select
              value={selectedBulkCategory}
              onChange={(e) => setSelectedBulkCategory(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "1rem",
                borderRadius: "8px",
                marginBottom: "1rem",
              }}
            >
              <option value="">-- Επιλογή --</option>
              {hymnCategories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
              }}
            >
              <button onClick={() => setShowCategoryModal(false)}>
                Ακύρωση
              </button>
              <button
                disabled={!selectedBulkCategory}
                onClick={async () => {
                  await handleBulkAddToCategory(selectedBulkCategory);
                  setShowCategoryModal(false);
                }}
              >
                ΟΚ
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
