import { React, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/Home";
import { FileUpload } from "./pages/FileUpload";
import { HymnsIndex } from "./pages/HymnsIndex";
import { Favorites } from "./pages/Favorites";
import { Hymn } from "./pages/Hymn";
import { AuthProvider } from "./contexts/authContext/Index";
import Login from "./components/auth/Login";
import { NotFound } from "./pages/NotFound";
import { Search } from "./pages/Search";
import NavBar from "./components/NavBar";
import { ChordsProvider } from "./components/ChordsContext";
import MyBottomNavigation from "./components/MyBottomNavigation";
import Transpose from "./components/Transpose";
import CategoryDetails from "./components/CategoryDetails";
import Tuner from "./pages/Tuner";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  const [chordsList, setChordsList] = useState([]);
  const [showBottomNav, setShowBottomNav] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      setShowBottomNav(currentScrollTop < 40);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="app">
      <main className="content">
        <AuthProvider>
          <ChordsProvider value={{ chordsList, setChordsList }}>
            <NavBar />
            <div className="page" style={{ paddingTop: 0 }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/Index"
                  element={
                    <PrivateRoute>
                      <HymnsIndex />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Search"
                  element={
                    <PrivateRoute>
                      <Search />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Favorites"
                  element={
                    <PrivateRoute>
                      <Favorites />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/FileUpload"
                  element={
                    <PrivateRoute>
                      <FileUpload />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Hymn"
                  element={
                    <PrivateRoute>
                      <Hymn />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Tuner"
                  element={
                    <PrivateRoute>
                      <Tuner />
                    </PrivateRoute>
                  }
                />
                <Route path="/Login" element={<Login />} />
                <Route
                  path="/Category"
                  element={
                    <PrivateRoute>
                      <CategoryDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <NotFound />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </div>
            <Transpose />
            {showBottomNav && <MyBottomNavigation />}
          </ChordsProvider>
        </AuthProvider>
      </main>
    </div>
  );
}

export default App;
