import React, { useRef } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import HomeIcon from "../assets/bottom/HomeIcon.png";
import IndexIcon from "../assets/bottom/IndexIcon.png";
import SearchIcon from "../assets/bottom/SearchIcon.png";
import FavoritesIcon from "../assets/bottom/FavoritesIcon.png";
import TunerIcon from "../assets/bottom/TunerIcon.png";
import LoginIcon from "../assets/bottom/LoginIcon.png";
import LogoutIcon from "../assets/bottom/LogoutIcon.png";
import UploadIcon from "../assets/bottom/UploadIcon.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/authContext/Index";
import { doSignOut } from "../firebase/auth";
import "./MyBottomNavigation.css";

export default function MyBottomNavigation() {
  const ref = useRef(null);
  const { userLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box ref={ref}>
      <CssBaseline />
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: "#141156",
        }}
        elevation={3}
        className="navigation"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 55,
          }}
        >
          {userLoggedIn && (
            <>
              <img
                src={HomeIcon}
                alt="Home Icon"
                className={`navigation-icon ${
                  location.pathname === "/" ? "selected" : ""
                }`}
                onClick={() => navigate("/")}
              />
              <img
                src={IndexIcon}
                alt="Index Icon"
                className={`navigation-icon ${
                  location.pathname === "/Index" ? "selected" : ""
                }`}
                onClick={() => navigate("/Index")}
              />
              <img
                src={SearchIcon}
                alt="Search Icon"
                className={`navigation-icon ${
                  location.pathname === "/Search" ? "selected" : ""
                }`}
                onClick={() => navigate("/Search")}
              />
              <img
                src={FavoritesIcon}
                alt="Favorites Icon"
                className={`navigation-icon ${
                  location.pathname === "/Favorites" ? "selected" : ""
                }`}
                onClick={() => navigate("/Favorites")}
              />
              <img
                src={TunerIcon}
                alt="Tuner Icon"
                className={`navigation-icon ${
                  location.pathname === "/Tuner" ? "selected" : ""
                }`}
                onClick={() => navigate("/Tuner")}
              />
            </>
          )}
          {userLoggedIn && (
            <img
              src={UploadIcon}
              alt="Upload Icon"
              className="navigation-icon  mobile-hide"
              onClick={() => navigate("/FileUpload")}
            />
          )}
          {userLoggedIn ? (
            <img
              src={LogoutIcon}
              alt="Logout Icon"
              className="navigation-icon mobile-hide"
              onClick={() => {
                doSignOut().then(() => {
                  navigate("/");
                });
              }}
            />
          ) : (
            <img
              src={LoginIcon}
              alt="Login Icon"
              className="navigation-icon mobile-hide"
              onClick={() => {
                navigate("/Login");
              }}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
}
