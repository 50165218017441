import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import showUpdateNotification from "./showUpdateNotification";
import showIosInstallPrompt from "./showIosInstallPrompt";
import initializeAndroidInstallPrompt from "./showAndroidInstallPrompt";
import "./App.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a4ada4020a3fc7b7634751c0a983f457@o4507265052114944.ingest.de.sentry.io/4507265059455056",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     showUpdateNotification(registration);
//   },
// });

function isIos() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}

function isAndroid() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /android/.test(userAgent);
}

function isInStandaloneMode() {
  return "standalone" in window.navigator && window.navigator.standalone;
}
