import { useState, useRef, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { majorScales, minorScales } from "../assets/scales";
import { hymnCategories } from "../assets/hymnCategories";
import validate from "validate.js";
import { ToastService } from "./Toast";
import {
  collection,
  updateDoc,
  deleteDoc,
  getDocs,
  doc,
  where,
  query,
} from "firebase/firestore";
import { db, storage } from "../firebase/firebase";
import { ref, deleteObject, uploadBytes } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ChordsContext } from "../components/ChordsContext";
import Switch from "@mui/material/Switch";
import { transliterateGreekToEnglish } from "../components/Helpers";

export const EditHymnData = ({
  hymnTitle,
  hymnScale,
  isPublic,
  hymnCategory,
}) => {
  const [editData, setEditData] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedNotesFile, setSelectedNotesFile] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(hymnTitle);
  const [selectedScale, setSelectedScale] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(hymnCategory);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [showToastService, setShowToastService] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const fileInputRef = useRef(null);
  const fileNotesInputRef = useRef(null);
  const navigate = useNavigate();
  const { editDetailsMode, setEditDetailsMode } = useContext(ChordsContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isHymnPublic, setIsHymnPublic] = useState(false);

  useEffect(() => {
    setEditData(editDetailsMode);
    setIsHymnPublic(isPublic);
    // eslint-disable-next-line
  }, [editDetailsMode]);

  useEffect(() => {
    setSelectedScale(hymnScale);
    let selected = majorScales
      .concat(minorScales)
      .find((option) => option.value === hymnScale);
    if (selected) setSelectedImage(selected.image);
    // eslint-disable-next-line
  }, [hymnScale]);

  const showToastMessage = (message) => {
    setToastMessage(message);
    setShowToastService(!showToastService);
    setTimeout(() => {
      setShowToastService(false);
    }, 1500);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/html") {
      setSelectedFile(e.target.files[0]);
    } else {
      showToastMessage("Παρακαλώ επιλέξτε αρχείο σε μορφή html.");
    }
  };

  const handleNotesFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedNotesFile(e.target.files[0]);
    } else {
      showToastMessage("Παρακαλώ επιλέξτε αρχείο σε μορφή pdf.");
    }
  };

  const handleCloseEditData = () => {
    setEditData(false);
    setEditDetailsMode(false);
  };

  const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);
  const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);

  const handleDeleteHymn = async () => {
    // Check if title already exists
    const existingHymnSnapshot = await getDocs(
      query(collection(db, "hymns"), where("title", "==", hymnTitle))
    );

    if (existingHymnSnapshot.empty) {
      setSelectedTitle("");
      setSelectedScale("");
      setSelectedNumber("");
      setSelectedCategory("");
      setEditData(false);
      return;
    }
    const hymnDoc = existingHymnSnapshot.docs[0];
    const hymnDocRef = doc(db, "hymns", hymnDoc.id);

    const hymnData = hymnDoc.data();
    const fileId = hymnData.fileId;
    const notesFileId = hymnData.notesFileId;

    if (fileId !== "") {
      const storageRef = ref(storage, `hymns/${fileId}`);
      await deleteObject(storageRef);
      const chordsstorageRef = ref(storage, `chords/${fileId}`);
      await deleteObject(chordsstorageRef);
    }

    if (notesFileId !== "") {
      const storageRef = ref(storage, `hymns/${notesFileId}`);
      await deleteObject(storageRef);
      const notesstorageRef = ref(storage, `notes/${notesFileId}`);
      await deleteObject(notesstorageRef);
    }

    // Delete the document
    await deleteDoc(hymnDocRef);

    setSelectedTitle("");
    setSelectedScale("");
    setSelectedNumber("");
    setSelectedCategory("");
    setEditData(false);
    setEditDetailsMode(false);
    handleCloseDeleteConfirmation();
    navigate("/");
  };

  const handleSaveEditData = async () => {
    // Check if title already exists
    const existingHymnSnapshot = await getDocs(
      query(collection(db, "hymns"), where("title", "==", hymnTitle))
    );
    if (hymnTitle !== selectedTitle) {
      const otherHymnSnapshot = await getDocs(
        query(collection(db, "hymns"), where("title", "==", selectedTitle))
      );
      if (!otherHymnSnapshot.empty) {
        const otherHymnDoc = otherHymnSnapshot.docs[0];
        const otherData = otherHymnDoc.data();
        if (otherData.title === selectedTitle && selectedTitle !== hymnTitle) {
          showToastMessage("Ο τίτλος υπάρχει ήδη");
          return;
        }
      }
    }

    if (existingHymnSnapshot.empty) {
      setSelectedTitle("");
      setSelectedScale("");
      setSelectedNumber("");
      setSelectedCategory("");
      setEditData(false);
      return;
    }
    const hymnDoc = existingHymnSnapshot.docs[0];
    const hymnDocRef = doc(db, "hymns", hymnDoc.id);

    const data = hymnDoc.data();
    data.title = selectedTitle !== "" ? selectedTitle : data.title;
    data.scale = selectedScale !== "" ? selectedScale : data.scale;
    data.number = selectedNumber !== "" ? selectedNumber : data.number;
    data.category = selectedCategory !== "" ? selectedCategory : data.category;
    data.visible = isHymnPublic;

    // Convert Greek to English
    const cleanTitle = transliterateGreekToEnglish(data.title);

    if (selectedFile) {
      const fileId = `${cleanTitle}_chords_${uuidv4()}.html`;
      const storageRef = ref(storage, `hymns/${fileId}`);
      // Upload the file data
      await uploadBytes(storageRef, selectedFile);
      const chordsstorageRef = ref(storage, `chords/${fileId}`);
      // Upload the file data
      await uploadBytes(chordsstorageRef, selectedFile);
      //delete old file if not empty
      if (data.fileId !== "") {
        const oldStorageRef = ref(storage, `hymns/${data.fileId}`);
        await deleteObject(oldStorageRef);
        const oldchordsStorageRef = ref(storage, `chords/${data.fileId}`);
        await deleteObject(oldchordsStorageRef);
      }

      data.fileId = fileId;
    }

    if (selectedNotesFile) {
      const notesFileId = `${cleanTitle}_notes_${uuidv4()}.pdf`;
      const storageNotesRef = ref(storage, `hymns/${notesFileId}`);
      // Upload the file data
      await uploadBytes(storageNotesRef, selectedNotesFile);
      const storagenotesNotesRef = ref(storage, `notes/${notesFileId}`);
      // Upload the file data
      await uploadBytes(storagenotesNotesRef, selectedNotesFile);
      //delete old file
      if (data.notesFileId !== "") {
        const storageRef = ref(storage, `hymns/${data.notesFileId}`);
        await deleteObject(storageRef);
        const storagenotesRef = ref(storage, `notes/${data.notesFileId}`);
        await deleteObject(storagenotesRef);
      }
      data.notesFileId = notesFileId;
    }

    //update the document
    await updateDoc(hymnDocRef, data);

    setSelectedTitle("");
    setSelectedScale("");
    setSelectedNumber("");
    setSelectedCategory("");
    setEditData(false);
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("title", data.title);

    window.location.href = currentUrl.toString();
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;

    if (!title) {
      setSelectedTitle(title);
      return;
    }
    const constraints = { title: { format: /[\u0370-\u03FF \-',.0-9]+/ } };
    const validation = validate({ title }, constraints);

    if (!validation) {
      setSelectedTitle(title);
    } else {
      showToastMessage(
        "Ο τίτλος πρέπει να περιλαμβάνει μόνο ελληνικούς χαρακτήρες"
      );
    }
  };

  const handleNumberChange = (e) => {
    setSelectedNumber(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleScaleChange = (e) => {
    setSelectedScale(e.target.value);
    let selected = majorScales
      .concat(minorScales)
      .find((option) => option.value === e.target.value);
    if (selected) setSelectedImage(selected.image);
  };

  const handleVisibilityChange = (e) => {
    setIsHymnPublic(e.target.checked);
  };

  return (
    <>
      <Modal show={editData} onHide={handleCloseEditData}>
        <Modal.Header closeButton>
          <Modal.Title>Επεξεργασία Στοιχείων Ύμνου</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center">
          <div className=" pt-2 mx-auto">
            <Form.Label>Τίτλος</Form.Label>
            <Form.Control
              type="text"
              value={selectedTitle}
              onChange={handleTitleChange}
              placeholder="Όνομα τίτλου"
            />
          </div>
          <div className="mx-auto pt-4">
            <Form.Label>Αρχείο (στίχοι με συγχορδίες)</Form.Label>
            <Form.Control
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <div className="mx-auto pt-4">
            <Form.Label>Αρχείο με νότες (προαιρετικά)</Form.Label>
            <Form.Control
              type="file"
              ref={fileNotesInputRef}
              onChange={handleNotesFileChange}
            />
          </div>
          <div className="mx-auto pt-4">
            <Form.Label>Αριθμός Ύμνου (προαιρετικά)</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={selectedNumber}
              onChange={handleNumberChange}
              placeholder="Αριθμός"
            />
          </div>
          <div className="w-60 pt-4 pb-4 mx-auto">
            <Form.Label>Τονικότητα</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={selectedScale}
              onChange={handleScaleChange}
            >
              <option key="0" value="0" label="Τονικότητα"></option>
              {majorScales.concat(minorScales).map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  style={{ backgroundImage: `url(${option.image})` }}
                >
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <img className="pt-4 mx-auto" src={selectedImage} alt="" />
          </div>
          <div className="w-60 pt-4 pb-4 mx-auto">
            <Form.Label>Κατηγορία</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option key="0" value="0" label="Κατηγορία"></option>
              {hymnCategories.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="pt-4 mx-auto">
            <Form.Label>Δημοσίευση ύμνου</Form.Label>
            <Switch
              checked={isHymnPublic}
              onChange={handleVisibilityChange}
              name="visibilityToggle"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          {showToastService && <ToastService message={toastMessage} />} {}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center">
          <Button variant="secondary" onClick={handleCloseEditData}>
            Κλείσιμο
          </Button>
          <Button variant="danger" onClick={handleShowDeleteConfirmation}>
            Διαγραφή Ύμνου
          </Button>
          <Button variant="primary" onClick={handleSaveEditData}>
            Αποθήκευση
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={handleCloseDeleteConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Επιβεβαίωση Διαγραφής</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Είστε σίγουροι ότι θέλετε να διαγράψετε τον ύμνο;
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteConfirmation}>
            Ακύρωση
          </Button>
          <Button variant="danger" onClick={handleDeleteHymn}>
            Διαγραφή
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
