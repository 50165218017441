import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext/Index";

const PrivateRoute = ({ children }) => {
  const { userLoggedIn } = useAuth();

  return userLoggedIn ? children : <Navigate to="/Login" replace />;
};

export default PrivateRoute;
