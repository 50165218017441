import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { ChordsContext } from "./ChordsContext";
import Box from "@mui/material/Box";
import ChordsIcon from "../assets/hymn/chords.png";
import ChordsIconSelected from "../assets/hymn/chords_selected.png";
import AddToFavoritesIcon from "../assets/hymn/add_to_favorites.png";
import AddToFavoritesIconSelected from "../assets/hymn/add_to_favorites_selected.png";
import DownloadIcon from "../assets/hymn/download.png";
import DownloadIconSelected from "../assets/hymn/download_selected.png";
import LeftIcon from "../assets/hymn/left.png";
import LeftIconSelected from "../assets/hymn/left_selected.png";
import RightIcon from "../assets/hymn/right.png";
import RightIconSelected from "../assets/hymn/right_selected.png";
import NoteIcon from "../assets/hymn/note.png";
import NoteIconSelected from "../assets/hymn/note_selected.png";
import SheetIcon from "../assets/hymn/sheet.png";
import SheetIconSelected from "../assets/hymn/sheet_selected.png";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { useAuth } from "../contexts/authContext/Index";
import { EditHymnData } from "../components/EditHymnData";
import AddToFavorites from "../components/AddToFavorites";
import "./HymnHeader.css";
import { DataArray } from "@mui/icons-material";
import { Check } from "@material-ui/icons";

const HymnHeader = ({ isHymnPublic, hymnScale, pdfUrl, hymnCategory }) => {
  const {
    notesMode,
    setNotesMode,
    setEditMode,
    setResetTranspose,
    setZoom,
    notesPdfUrl,
    setSaveMode,
    setEditDetailsMode,
    transpose,
    transposedScale,
    transposedChords,
    transposedScaledImage,
    setDoTransposeDown,
    setDoTransposeUp,
  } = useContext(ChordsContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedModeIcon, setSelectedModeIcon] = useState("SheetIcon");
  const [isFavorite, setIsFavorite] = useState(null);
  const [showAddToFavoritesModal, setShowAddToFavoritesModal] = useState(false);
  const [showChordsModal, setShowChordsModal] = useState(false);
  const location = useLocation();
  const { userLoggedIn } = useAuth();
  let pageTitle = location.pathname;
  if (location.pathname === "/Hymn") {
    let params = new URLSearchParams(location.search);
    pageTitle = params.get("title");
  }

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const categories = storedFavorites.reduce((acc, item, index) => {
      if (item.content && item.content.includes(pageTitle)) {
        acc.push(index);
      }
      return acc;
    }, []);
    setIsFavorite(categories.length > 0);
  }, [showAddToFavoritesModal, pageTitle]);

  useEffect(() => {
    if (notesMode && notesPdfUrl !== "") {
      setSelectedModeIcon("NoteIcon");
    }
  }, [notesMode, notesPdfUrl]);

  const handleIconClick = (iconName) => {
    switch (iconName) {
      case "LeftIcon":
        if (notesMode) return;
        setSelectedIcon(iconName);
        setDoTransposeDown(true);
        break;
      case "RightIcon":
        if (notesMode) return;
        setSelectedIcon(iconName);
        setDoTransposeUp(true);
        break;
      case "ChordsIcon":
        setSelectedIcon(iconName);
        setShowChordsModal(true);
        break;
      case "DownloadIcon":
        setSelectedIcon(iconName);
        handleDownload();
        break;
      case "EditIcon":
        setSelectedIcon(iconName);
        handleEdit();
        break;
      case "SaveIcon":
        setSelectedIcon(iconName);
        handleSave();
        break;
      case "DetailsIcon":
        setSelectedIcon(iconName);
        handleEditDetails();
        break;
      default:
        break;
    }
    setTimeout(() => {
      setSelectedIcon(null);
    }, 200);
  };

  const handleIconModeClick = (iconName) => {
    if (notesPdfUrl === "" || pdfUrl === "") return;
    setSelectedModeIcon(iconName);
    setNotesMode(iconName === "NoteIcon");
  };

  const handleAddToFavorites = () => {
    setShowAddToFavoritesModal(!showAddToFavoritesModal);
  };

  const handleDownload = () => {
    if (selectedModeIcon === "SheetIcon") {
      const pdfContainer =
        document.querySelector(".editable-content")?.innerHTML;
      const element = document.createElement("a");
      const file = new Blob([pdfContainer], { type: "text/html" });
      element.href = URL.createObjectURL(file);
      element.download = pageTitle;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else if (selectedModeIcon === "NoteIcon") {
      const newWindow = window.open(notesPdfUrl, "_blank");
      if (newWindow) {
        newWindow.onload = () => {
          newWindow.print();
        };
      }
    }
  };

  const handleEdit = () => {
    setResetTranspose((prev) => prev + 1);
    setEditMode(!isEditMode);
    setIsEditMode(!isEditMode);
    if (!isEditMode) {
      setZoom(1);
    }
  };

  const handleSave = () => {
    setSaveMode(true);
  };

  const handleEditDetails = () => {
    setEditDetailsMode(true);
  };

  const handleChordClick = (soundPath) => {
    if (soundPath) {
      const audio = new Audio(process.env.PUBLIC_URL + "/" + soundPath);
      audio.play();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          height: 66,
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "1rem",
        }}
      >
        <div className="header-item pr-1">
          <img
            src={selectedIcon === "LeftIcon" ? LeftIconSelected : LeftIcon}
            className={`header-icon ${notesMode ? "disabled" : ""}`}
            alt=""
            onClick={() => handleIconClick("LeftIcon")}
          />
          <img
            src={selectedIcon === "RightIcon" ? RightIconSelected : RightIcon}
            className={`header-icon ${notesMode ? "disabled" : ""}`}
            alt=""
            onClick={() => handleIconClick("RightIcon")}
          />
          <div className="header-text">
            Transpose{" "}
            {transpose > 0 ? "+" + transpose : transpose < 0 ? transpose : ""}
          </div>
        </div>
        <div className="header-item">
          <img
            src={
              selectedModeIcon === "SheetIcon" ? SheetIconSelected : SheetIcon
            }
            className={`header-icon ${pdfUrl === "" ? "disabled" : ""}`}
            alt=""
            onClick={() => handleIconModeClick("SheetIcon")}
          />
          <img
            src={selectedModeIcon === "NoteIcon" ? NoteIconSelected : NoteIcon}
            className={`header-icon ${notesPdfUrl === "" ? "disabled" : ""}`}
            alt=""
            onClick={() => handleIconModeClick("NoteIcon")}
          />
          <img
            src={
              selectedIcon === "ChordsIcon" ? ChordsIconSelected : ChordsIcon
            }
            className="header-icon"
            alt=""
            onClick={() => handleIconClick("ChordsIcon")}
          />
          <img
            src={isFavorite ? AddToFavoritesIconSelected : AddToFavoritesIcon}
            className="header-icon"
            alt=""
            onClick={() => handleAddToFavorites()}
          />
          <img
            src={
              selectedIcon === "DownloadIcon"
                ? DownloadIconSelected
                : DownloadIcon
            }
            className="header-icon"
            alt=""
            onClick={() => handleIconClick("DownloadIcon")}
          />
        </div>
      </Box>
      {userLoggedIn && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            height: 66,
            paddingRight: "6rem",
            paddingTop: "1rem",
          }}
        >
          <IconButton
            onClick={() => handleIconClick("DetailsIcon")}
            style={{ width: "2.5rem", height: "2.5rem" }}
            aria-label="editdetails"
          >
            <DataArray style={{ fontSize: "2rem" }} />
          </IconButton>
          <IconButton
            onClick={handleEdit}
            style={{
              width: "2.5rem",
              height: "2.5rem",
              color: isEditMode ? "red" : "",
            }}
            aria-label="edit"
          >
            {isEditMode ? (
              <CloseIcon style={{ fontSize: "2rem" }} />
            ) : (
              <EditIcon style={{ fontSize: "2rem" }} />
            )}
          </IconButton>
          {isEditMode && (
            <IconButton
              onClick={() => handleIconClick("SaveIcon")}
              style={{ width: "2.5rem", height: "2.5rem", color: "green" }}
              aria-label="save"
            >
              <Check style={{ fontSize: "2rem" }} />
            </IconButton>
          )}
        </Box>
      )}
      <Modal show={showChordsModal} onHide={() => setShowChordsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Κλίμακα & Συγχορδίες</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-center flex-col">
            <div className="pl-2 font-bold">{transposedScale}</div>
            <div className="pl-2">
              <img src={transposedScaledImage} alt="" />
            </div>
            <div className="pl-2">
              <div className="display-row">
                {transposedChords.length > 0 &&
                  transposedChords.map((item, index) => (
                    <div key={index} className="item-block">
                      <div>{item.label}</div>
                      <img
                        src={item.image}
                        alt=""
                        onClick={() => handleChordClick(item.sound)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShowChordsModal(false)}
            style={{ textTransform: "none" }}
          >
            Κλείσιμο
          </Button>
        </Modal.Footer>
      </Modal>
      <AddToFavorites
        showAddToFavoritesModal={showAddToFavoritesModal}
        setShowAddToFavoritesModal={setShowAddToFavoritesModal}
        addHymnToFavorites={pageTitle}
      ></AddToFavorites>
      {userLoggedIn && (
        <EditHymnData
          hymnTitle={pageTitle}
          hymnScale={hymnScale}
          isPublic={isHymnPublic}
          hymnCategory={hymnCategory}
        />
      )}
    </>
  );
};

export default HymnHeader;
