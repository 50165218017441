export const hymnCategories = [
  "Δοξολογίας",
  "Ομολογίας",
  "Ευαγγελιστικοί",
  "Πίστης",
  "Αφιέρωσης",
  "Δέησης",
  "Προσευχής",
  "Δοκιμασίας",
  "Υπηρεσίας",
  "Γέννησης",
  "Σταύρωσης",
  "Ανάστασης",
  "Έλευσης",
  "Ουρανού",
  "Συναναστροφής",
  "Υμνωδίες",
  "Γαμήλιοι",
  "Παιδικοί",
];
